import { render, staticRenderFns } from "./MachineSlot.vue?vue&type=template&id=80375096&scoped=true&"
import script from "./MachineSlot.vue?vue&type=script&lang=js&"
export * from "./MachineSlot.vue?vue&type=script&lang=js&"
import style0 from "./MachineSlot.vue?vue&type=style&index=0&id=80375096&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80375096",
  null
  
)

export default component.exports