<template>
  <b-container :class="handleMainContainer">
    <MachineSlotMainFrame :vId="machine" :center="center" />

    <b-container class="onEffectBackgroundWhite bg-light top_10 mt-5" fluid v-if="bestgain">
      <h2>Les 10 derniers Gains:</h2>
      <b-table striped hover :items="top10" :fields="fields" responsive table-variant="light">
        <template v-slot:cell(dice1)="data">
          <b-img-lazy
            :src="convertIntCylinderToPicture(data.value)"
            :alt="data.value"
            class="scaleUpOnHover"
            width="35"
            thumbnail
            rounded
            fluid
          />
        </template>
        <template v-slot:cell(dice2)="data">
          <b-img-lazy
            :src="convertIntCylinderToPicture(data.value)"
            :alt="data.value"
            class="scaleUpOnHover"
            width="35"
            thumbnail
            rounded
            fluid
          />
        </template>
        <template v-slot:cell(dice3)="data">
          <b-img-lazy
            :src="convertIntCylinderToPicture(data.value)"
            :alt="data.value"
            class="scaleUpOnHover"
            width="35"
            thumbnail
            rounded
            fluid
          />
        </template>
      </b-table>
    </b-container>
  </b-container>
</template>

<script>
export default {
  props: {
    machine: {
      type: Number,
      required: true,
    },
    bestgain: {
      type: Boolean,
      default: false,
    },
    wall: {
      type: Boolean,
      default: false,
    },
    center: { type: Boolean, default: false },
  },
  components: {
    MachineSlotMainFrame: () => import("./MachineSlotMainFrame"),
  },
  data() {
    return {
      top10: [],
      fields: [
        { key: "date", label: "date" },
        {
          key: "won",
          label: "gain",
          formatter: (value, key, item) => {
            return item.won + "€";
          },
        },
        { key: "dice1", label: "cylindre 1" },
        { key: "dice2", label: "cylindre 2" },
        { key: "dice3", label: "cylindre 3" },
      ],
    };
  },
  computed: {
    handleMainContainer() {
      return this.wall ? "wall" : "";
    },
  },
  methods: {
    moneyFormatter(value) {
      return Number.parseFloat(value).toFixed(2);
    },
    convertIntCylinderToPicture(Cylinder) {
      switch (parseInt(Cylinder)) {
        case 1:
          return require("@/assets/img/machine_slots/1.png");
        case 2:
          return require("@/assets/img/machine_slots/2.png");
        case 3:
          return require("@/assets/img/machine_slots/3.png");
        case 4:
          return require("@/assets/img/machine_slots/4.png");
        case 5:
          return require("@/assets/img/machine_slots/5.png");
        case 6:
          return require("@/assets/img/machine_slots/6.png");
      }
    },
    extraInfo() {
      return (
        " currentURL: " +
        document.URL +
        "\n userAgent: " +
        navigator.userAgent +
        "\n platform: " +
        navigator.platform +
        "\n userid: " +
        localStorage.userid +
        "\n language: " +
        navigator.langauge +
        "\n cookies?: " +
        navigator.cookiesEnabled
      );
    },
    convertMysqlDate(dateTime = "2017-02-04 11:23:54") {
      const date = new Date(dateTime);
      return {
        d: date.getDate(),
        m: date.getMonth() + 1,
        Y: date.getFullYear(),
        H: ("0" + date.getHours()).slice(-2),
        i: ("0" + date.getMinutes()).slice(-2),
        s: ("0" + date.getSeconds()).slice(-2),
        fullDate:
          date.getDate() +
          " " +
          (date.getMonth() + 1) +
          " " +
          date.getFullYear() +
          " " +
          ("0" + date.getHours()).slice(-2) +
          ":" +
          ("0" + date.getMinutes()).slice(-2) +
          ":" +
          ("0" + date.getSeconds()).slice(-2),
      };
    },
  },
};
</script>

<style scoped>
:root {
  --main-bg-color: #1a242e;
  --main-txt-color: #8400ff;
}

.margin_top {
  margin-top: 3.25em;
}
.machine_slots_top10 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}
.machine_slots_top10 table {
  overflow-x: auto;
}
.machine_slots_top10 tr:nth-child(even) {
  background: lightgrey;
  color: black;
}
.machine_slots_top10 td img {
  max-width: 30px;
}

header,
footer {
  color: var(--main-txt-color);
  text-align: center;
}

.onEffectBackgroundWhite {
  background-color: #000020;
  color: transparent;
  text-shadow: 2px 2px 3px #cececece;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
}
.onEffectBackgroundBlue {
  text-shadow: 4px 4px 2px #333;
  padding: 0;
}

.top_10 {
  max-width: 768px;
  height: auto;
  text-align: center;
  background: white;
  padding: 30px 50px;
  color: #000020;
  border: 1px solid #35495e;
  border-radius: 5px;
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
}

.top_10 fieldset {
  border-radius: 10px;
  border: 1px dashed #35495e;
  -webkit-box-shadow: 4px 4px 2px 0px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 4px 4px 2px 0px rgba(51, 51, 51, 0.5);
  box-shadow: 4px 4px 2px 0px rgba(51, 51, 51, 0.5);
  transition: 0.5s;
}
/* fieldset_tag */
.top_10 fieldset:hover {
  -webkit-box-shadow: 0 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 0 transparent;
  box-shadow: 0 0 0 0 transparent;
  transition: 0.5s;
}

hr .vertical {
  width: 0.05em;
  height: auto;
}

pre {
  background: var(--main-bg-color);
  color: var(--main-txt-color);
}

/*
 *************************  Media Queries *************************
  color: gestion de la couleur (en bits/pixel).
  height: hauteur de la zone d'affichage (fenêtre).
  width: largeur de la zone d'affichage (fenêtre).
  device-height: hauteur du périphérique.
  device-width: largeur du périphérique.
  orientation: orientation du périphérique (portrait ou paysage).
  media: type d'écran de sortie. Quelques-unes des valeurs possibles :
    screen: écran « classique » ;
    handheld: périphérique mobile ;
    print: impression ;
    tv: télévision ;
    projection: projecteur ;
    all: tous les types d'écran. 
*/
@media screen and (max-width: 700px) {
  .top_10 {
    height: auto;
    background: whitesmoke;
    color: #000020;
    overflow-x: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: 0 0 0 0 transparent;
    -moz-box-shadow: 0 0 0 0 transparent;
    box-shadow: 0 0 0 0 transparent;
  }
  .top_10 fieldset {
    border-radius: 0;
    border: 0px dashed transparent;
    -webkit-box-shadow: 0px 0px 0px transparent;
    -moz-box-shadow: 0px 0px 0px transparent;
    box-shadow: 0px 0px 0px transparent;
  }
  ul {
    padding: 1em 0;
    width: 88%;
  }
  ul li {
    width: 88%;
  }

  hr.vertical {
    display: none;
  }
}
</style>
